import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Terms = ({ location }) => {
  return (
    <Layout location={location}>
      <section>
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-4 md:pt-40 md:pb-4">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
              <h1 className="h1 mb-4">Terms and conditions</h1>
              <p className="text-xl text-gray-600">
                Time to grab a cup of coffee.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*  Body of text  */}
      <section>
        <div className="max-w-5xl mx-auto px-4 sm:px-6">
          <div className="pb-12 md:pb-20">
            <div className="max-w-3xl mx-auto">
              <h3 className="h3 mb-3">1. Terms</h3>
              <p className="text-lg text-gray-600 mb-8">
                By accessing the website at https://encord.com, you are agreeing
                to be bound by these terms of service, all applicable laws and
                regulations, and agree that you are responsible for compliance
                with any applicable local laws. If you do not agree with any of
                these terms, you are prohibited from using or accessing this
                site. The materials contained in this website are protected by
                applicable copyright and trademark law.
              </p>
            </div>

            <div className="max-w-3xl mx-auto">
              <h3 className="h3 mb-3">2. User license</h3>
              <p className="text-lg text-gray-600 mb-8">
                Permission is granted to temporarily download one copy of the
                materials (information or software) on Cord Technologies
                Limited's ("Encord") website for personal, non-commercial
                transitory viewing only. This is the grant of a licence, not a
                transfer of title, and under this licence you may not: modify or
                copy the materials; use the materials for any commercial
                purpose, or for any public display (commercial or
                non-commercial); attempt to decompile or reverse engineer any
                software contained on Encord's website; remove any copyright or
                other proprietary notations from the materials; or transfer the
                materials to another person or "mirror" the materials on any
                other server. This licence shall automatically terminate if you
                violate any of these restrictions and may be terminated by
                Encord at any time. Upon terminating your viewing of these
                materials or upon the termination of this licence, you must
                destroy any downloaded materials in your possession whether in
                electronic or printed format.
              </p>
            </div>

            <div className="max-w-3xl mx-auto">
              <h3 className="h3 mb-3">3. Disclaimer</h3>
              <p className="text-lg text-gray-600 mb-8">
                The materials on Encord's website are provided on an 'as is'
                basis. Encord makes no warranties, expressed or implied, and
                hereby disclaims and negates all other warranties including,
                without limitation, implied warranties or conditions of
                merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other violation of
                rights. Further, Encord does not warrant or make any
                representations concerning the accuracy, likely results, or
                reliability of the use of the materials on its website or
                otherwise relating to such materials or on any sites linked to
                this site.
              </p>
            </div>

            <div className="max-w-3xl mx-auto">
              <h3 className="h3 mb-3">4. Limitations</h3>
              <p className="text-lg text-gray-600 mb-8">
                In no event shall Encord or its suppliers be liable for any
                damages (including, without limitation, damages for loss of data
                or profit, or due to business interruption) arising out of the
                use or inability to use the materials on Encord's website, even
                if Encord or a Encord authorised representative has been
                notified orally or in writing of the possibility of such damage.
                Because some jurisdictions do not allow limitations on implied
                warranties, or limitations of liability for consequential or
                incidental damages, these limitations may not apply to you.
              </p>
            </div>

            <div className="max-w-3xl mx-auto">
              <h3 className="h3 mb-3">5. Accuracy of materials</h3>
              <p className="text-lg text-gray-600 mb-8">
                The materials appearing on Encord website could include
                technical, typographical, or photographic errors. Encord does
                not warrant that any of the materials on its website are
                accurate, complete or current. Encord may make changes to the
                materials contained on its website at any time without notice.
                However Encord does not make any commitment to update the
                materials.
              </p>
            </div>

            <div className="max-w-3xl mx-auto">
              <h3 className="h3 mb-3">6. Links</h3>
              <p className="text-lg text-gray-600 mb-8">
                Encord has not reviewed all of the sites linked to its website
                and is not responsible for the contents of any such linked site.
                The inclusion of any link does not imply endorsement by Encord
                of the site. Use of any such linked website is at the user's own
                risk.
              </p>
            </div>

            <div className="max-w-3xl mx-auto">
              <h3 className="h3 mb-3">7. Modifications</h3>
              <p className="text-lg text-gray-600 mb-8">
                Encord may revise these terms of service for its website at any
                time without notice. By using this website you are agreeing to
                be bound by the then current version of these terms of service.
              </p>
            </div>

            <div className="max-w-3xl mx-auto">
              <h3 className="h3 mb-3">8. Governing law</h3>
              <p className="text-lg text-gray-600 mb-8">
                These terms and conditions shall be governed by and construed in
                accordance with the laws of England & Wales and the parties
                submit to the non-exclusive jurisdiction of the English courts.
              </p>
            </div>

            <div className="max-w-3xl mx-auto">
              <h3 className="h3 mb-3">9. Security Commitments</h3>
              <p className="text-lg text-gray-600 mb-8">
                Encord is GDPR and HIPAA compliant, and holds SOC2 Type II certification.
                We are committed to the utmost standards of security and availability.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Terms;

export const Head = () => (
  <SEO
    title="Encord | Terms and Conditions"
    description="Read our full terms and conditions."
  />
);
